<div class="container my-5">
  <div class="row">
    <div class="col-12 mb-5">
      <h1>Saintes</h1>
      <div class="col-12">
        <a [routerLink]="'/plateau/'" class="btn btn-primary"
          [style.font-size]="'15px'">{{'common_publish' | translate}}</a>
      </div>
    </div>

    <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4" *appRestrictedByScope="{
			model: 'point_of_interest',
			action: 'count'
		}">
      <hpf-point-of-interest-count></hpf-point-of-interest-count>
    </div>
    <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4" *appRestrictedByScope="{
			model: 'user',
			action: 'count' 
		}">
      <hpf-user-count></hpf-user-count>
    </div>
    <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4" *appRestrictedByScope="{
		model: 'video',
		action: 'count'
	}">
      <hpf-video-count></hpf-video-count>
    </div>
    <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4" *appRestrictedByScope="{
		model: 'audio',
		action: 'count'
	}">
      <hpf-audio-count></hpf-audio-count>
    </div>
    <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4" *appRestrictedByScope="{
	model: 'image',
	action: 'count'
}">
      <hpf-image-count></hpf-image-count>
    </div>
    <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4" *appRestrictedByScope="{
		model: 'language',
		action: 'count'
	}">
      <hpf-language-count></hpf-language-count>
    </div>
    <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4" *appRestrictedByScope="{
		model: 'category',
		action: 'count'
	}">
      <hpf-category-count></hpf-category-count>
    </div>

    <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4" *appRestrictedByScope="{
	model: 'route',
	action: 'count'
}">
      <hpf-route-count></hpf-route-count>
    </div>

    <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4" *appRestrictedByScope="{
		model: 'plateau',
		action: 'count'
	}">
      <hpf-plateau-count></hpf-plateau-count>
    </div>
    <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4" *appRestrictedByScope="{
			model: 'audio_description',
			action: 'count'
		}">
      <hpf-audio-description-count></hpf-audio-description-count>
    </div>

    <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4" *appRestrictedByScope="{
		model: 'audio_description',
		action: 'count'
	}">
      <hpf-audio-description-count></hpf-audio-description-count>
    </div>

    <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4" *appRestrictedByScope="{
	model: 'diaporama',
	action: 'count'
}">
      <hpf-diaporama-count></hpf-diaporama-count>
    </div>

    <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4" *appRestrictedByScope="{
	model: 'about',
	action: 'count'}">
      <hpf-about-count></hpf-about-count>
    </div>
    <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4" *appRestrictedByScope="{
			model: 'credit',
			action: 'count'
		}">
      <hpf-credit-count></hpf-credit-count>
    </div>



    <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4" *appRestrictedByScope="{
			model: 'marker',
			action: 'count'
		}">
      <hpf-marker-count></hpf-marker-count>
    </div>








  </div>

</div>
